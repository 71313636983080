import { useState } from "react";
import { ChecklistCreateModel, ChecklistModel, CorreosLista } from "../../types/checklist"
import { EmailInput } from '../UtilsComponents/EmailInput';
import ClearIcon from '@mui/icons-material/Clear';
import { UseFormSetValue } from "react-hook-form";

interface Props {
    correosAdjuntos: CorreosLista;
    setValue:  UseFormSetValue<ChecklistModel > | UseFormSetValue<ChecklistCreateModel >;
    mostrarCorreoAgregados?: boolean;
}

export function AddCorreosAdjuntos({ correosAdjuntos, setValue, mostrarCorreoAgregados = true }: Props) {
    const [showEmailInput, setShowEmailInput] = useState<boolean>(false);

    return (
        <div className="w-full">
            <div>
                {mostrarCorreoAgregados && (
                <>
                <p className="font-semibold">Correos Agregados</p>
                <div className="max-h-[100px] overflow-auto sm:grid sm:grid-cols-2 sm:my-2 sm:gap-x-3">
                    {correosAdjuntos.correos.length === 0
                    ? <p className="">No Hay correos adjuntos para esta lista.</p>
                    : correosAdjuntos.correos.map((correo, index) => (
                        <div className="flex justify-between group">
                            <p>{correo}</p>
                            <div
                                className="hover:bg-gray-100 hover:rounded opacity-0 group-hover:opacity-100 transition duration-150 cursor-pointer h-[24px] flex justify-center items-center">
                                <ClearIcon />
                            </div>
                        </div>
                    ))}
                </div>
                </>)}
            </div>
            {showEmailInput
            ? <>
                <EmailInput
                    setValue={setValue}/>
            </>
            : <>
                <p
                    className="italic cursor-pointer no-underline hover:underline hover:text-blue-900"
                    onClick={() => setShowEmailInput(!showEmailInput)}>
                    Agregar más correos
                </p>
            </>
            }
        </div>
    )
}