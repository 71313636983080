import { useEffect, useState } from "react";
import { ChartLayout, VistaGeneral } from "../../../types/agenda";
import { FiltrosModel } from "../../../types";
import { AgendaServices } from "../../../services";
import { useUser } from "../../../hooks/useUser";
import { LoadingComponent } from "../../UtilsComponents";
import { VistaChart } from "./VistaChart";

interface Props {
    filtros: FiltrosModel;
    layout: ChartLayout;
}

export function VistaDias({ filtros, layout }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const agendaService = new AgendaServices(token);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataVistaGeneral, setDataVistaGeneral] = useState<VistaGeneral>({
        labels: [],
        general: [],
        proyeccionGeneral: [],
        proyeccionEspecialista: [],
        especialista: [],
        generalColors: [],
        especialistaColors: [],
        datasets: []
    });

    useEffect(() => {
        setIsLoading(true);

        agendaService.getVistaGeneralData(
            filtros.fechaInicial,
            filtros.fechaFinal,
            filtros.sucursales,
            filtros.especialidad,
            filtros.doctores,
            filtros.tipoDoctor,
        ).then(data => {
            setDataVistaGeneral(data)
            setIsLoading(false);
        });
    }, [filtros]);

    return (
        <div className={`${layout == "Row" ? "w-full" : "flex justify-center" } rounded-lg shadow-sm p-4 bg-slate-50 border-2 border-slate-200`}>
            <div className={`${layout == "Row" ? "" : "w-10/12"}`}>
                {isLoading
                ? <LoadingComponent />
                : <VistaChart
                    chartConfig={dataVistaGeneral} />
                }
            </div>
        </div>
    );
}