import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LegendItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { VistaGeneral } from '../../../types/agenda';
import { addPaddingToAxisYValue, getGreatestValueFromDataset } from '../../../utilities/calculateMaxRange';
import { useState } from 'react';

ChartJs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

interface Props {
    chartConfig: VistaGeneral;
}

export function VistaChart({ chartConfig }: Props) {
    const proyeccionIndex: number[] = [];
    let greatestValue = getGreatestValueFromDataset(chartConfig.datasets);

    const max = addPaddingToAxisYValue(greatestValue);

    const options = {
        animation: {
            duration: 400,
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: max,
                stacked: true
            },
            x: {
                stacked: true
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                display: true,
                // TODO: Esta funcion enviara a otra parte alv.
                onClick: function(e: any, legendItem: any, legend: any) {
                    const index = legendItem.datasetIndex;
                    const ci = legend.chart;

                    ci.$datalabels._datasets.map((dataset: any, index: number) => {
                        return dataset.map((data: any, index: any) => {
                            const label = data.$context.dataset.label;

                            if(label.includes("Proyeccion")) {
                                const datasetIndex = data.$context.datasetIndex;

                                if(proyeccionIndex.includes(datasetIndex))
                                    return null;

                                return proyeccionIndex.push(datasetIndex);
                            }
                        });
                    })

                    if(legendItem.text == "Proyección")
                    {
                        proyeccionIndex.map((index) => {
                            if(ci.isDatasetVisible(index)) {
                                ci.hide(index);
                                legendItem.hidden = true;
                            } else {
                                ci.show(index);
                                legendItem.hidden = false;
                            }
                        });
                    } else {
                        if(ci.isDatasetVisible(index)) {
                            ci.hide(index);
                            legendItem.hidden = true;
                        } else {
                            ci.show(index);
                            legendItem.hidden = false;
                        }
                    }
                },
                labels: {
                    // TODO: Esta funcion enviara a otra parte alv.
                    generateLabels: function (chart: any) {
                        const datasets = chart.data.datasets;

                        const uniqueLabels = {};
                        
                        return datasets.map((dataset, index) => {
                            let labelText = dataset.label;

                            // Cambiar las etiquetas de proyección para que todas se muestren como 'proyección'
                            if (dataset.label.includes('Proyeccion'))
                                labelText = 'Proyección';

                            // Verificar si ya hemos agregado esta etiqueta
                            if (uniqueLabels[labelText])
                                // Ignorar duplicados
                                return null; 

                            uniqueLabels[labelText] = true;

                            return {
                                text: labelText,
                                fillStyle: dataset.backgroundColor,
                                hidden: !chart.isDatasetVisible(index),
                                datasetIndex: index,
                            };
                        // Eliminar etiquetas nulas
                        }).filter(label => label !== null); 
                    },
                }
            },
            title: {
                display: false,
                text: '',
            },
            datalabels: {
                display: true,
                color: "rgba(17, 24, 39, 1)",
                formatter: function(value: any, _context: any) {

                    if(value <= 0)
                        return "";

                    if(_context.dataset.label == "Proyeccion Generales")
                        return `${chartConfig.datasets[0].projectionData[_context.dataIndex]}%`

                    if(_context.dataset.label == "Proyeccion Especialistas")
                        return `${chartConfig.datasets[1].projectionData[_context.dataIndex]}%`

                    return `${value}%`
                },
            },
            annotation: {
                annotations: {
                    line1: {
                    type: 'line',
                    yMin: 100,
                    yMax: 100,
                    borderColor: 'red',
                    borderWidth: 1,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        let label = context.dataset.label;
                        if(label == "Proyeccion Generales")
                            return `${label}: ${chartConfig.datasets[0].projectionData[context.dataIndex]}%`;

                        if(label == "Proyeccion Especialistas") 
                            return `${label}: ${chartConfig.datasets[1].projectionData[context.dataIndex]}%`;

                        if(label == "Generales" || label == "Especialidades" || label == "Doctores") 
                            return `${label}: ${chartConfig.datasets[0].data[context.dataIndex]}%`;

                        if(label == "Especialistas") 
                            return `${label}: ${chartConfig.datasets[1].data[context.dataIndex]}%`;

                        return label;
                    }

                }
            }
        },
    };

    const dataset: any = []

    if(chartConfig.datasets.length > 0) {
        chartConfig.datasets.map((data, index) => {

            const dataProjection: number[] = []

            for (let index = 0; index < data.projectionData.length; index++) {
                let value = data.projectionData[index] - data.data[index];
                dataProjection.push(value);
            }

            dataset.push({
                label: data.datasetName,
                data: data.data,
                backgroundColor: data.colors,
                stack: `Stack ${data.datasetName}`,
            });

            dataset.push({
                label: `Proyeccion ${data.datasetName}`,
                data: dataProjection,
                backgroundColor: "rgba(72, 72, 72, 0.5)",
                stack: `Stack ${data.datasetName}`,
            });
        });
    }

    const data = {
        labels: chartConfig.labels,
        datasets: dataset,
    };

    return (
        <Bar 
            redraw={true}
            options={options}
            data={data}
            plugins={[ChartDataLabels,annotationPlugin]}/>
    );
}