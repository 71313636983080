import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { Checkbox } from "../UtilsComponents/FiltrosComponents/AgendaFilter/Checkbox";
import { ChecklistCreateModel, ChecklistModel, SucursalModel } from "../../types";
import { UseFormSetValue } from "react-hook-form";

interface Props {
    sucursales: SucursalModel[];
    //setValue: UseFormSetValue<ChecklistCreateModel> | UseFormSetValue<ChecklistModel>;
    setValue: any;
    sucursalesAsignadas?: number[];
}

export function AsignarSucursalInput({ sucursales, setValue, sucursalesAsignadas = [] }: Props) {
    const [selectedOptions, setSelectedOptions] = useState<Array<number>>(sucursalesAsignadas);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        setSelectedOptions((prevSelectedOptions): number[] =>
            prevSelectedOptions.includes(value)
                ? prevSelectedOptions.filter((option) => option !== value)
                : [...prevSelectedOptions, value]
        );
    };

    useEffect(() => {
        setValue("sucursalesAsignadas", selectedOptions, { shouldDirty: true });

    }, [selectedOptions]);

    return (
        <div className="grid grid-cols-2">
            {sucursales.length == 0
            ? <p className="italic text-center text-gray-500 font-normal">No se encontraron sucursales</p>
            : sucursales.map((sucursal, index) => (
                <Fragment  key={index}>
                    <Checkbox 
                        isChecked={selectedOptions.includes(sucursal.id)}
                        handleCheckboxChange={handleCheckboxChange}
                        value={sucursal.id}
                        text={`${sucursal.smallDir}`}
                        especialidad={0}/>
                </Fragment>
            ))}
        </div>
    );
}