import { useState } from "react";
import { ChartDisplay } from "../../types/agenda";
import { formatDate } from "../../utilities/showData";
import { FiltrosModel, ShowFiltros } from "../../types";
import { AgendaFiltros } from "../UtilsComponents/FiltrosComponents/AgendaFiltros";
import { DisplayCharts } from "./DisplayCharts";
import { VistaDias } from "./ChartViews/VistaDias";
import { VistaSucursal } from "./ChartViews/VistaSucursal";
import { VistaEspecialidad } from "./ChartViews/VistaEspecialidad";
import { VistaDoctor } from "./ChartViews/VistaDoctor";
import { useUser } from "../../hooks/useUser";
import { AgendaServices } from "../../services";

export function VistaGenearl() {
    const [chartDisplay, setChartDisplay] = useState<ChartDisplay>({
        layout: "Row",
        showDaysChart: true,
        showSucursalChart: true,
        showEspecialistaChart:true, 
        showDoctoraChart: true,
    });

    const [filtros, setFiltros] = useState<FiltrosModel>({
        fechaInicial: formatDate(new Date(new Date().setDate(new Date().getDate() - 7))),
        fechaFinal: formatDate(new Date()),
        categoriaId: 0,
        sucursalId: 0,
        estado: "",
        responsableId: 0,
        subcategoriaId: 0,
        urgencia: "",
        checklistId: 0,
        especialidad: [],
        doctores: [],
        tipoDoctor: [],
        sucursales: [],
    });

    const filtrosShowed: ShowFiltros = {
        fechas: true, 
        categoria: false,
        estado: false,
        sucursal: true,
        responsable: false,
        urgencia: false,
        checklist: false,
        especialidad: true,
        doctores: true,
        tipoDoctor: true,
    }

    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const agendaService = new AgendaServices(token);

    const onClickExportCsv = async () => {
        try {

            const response = await agendaService.exportAgendaData(
                filtros.fechaInicial,
                filtros.fechaFinal,
                filtros.sucursalId,
                filtros.especialidad,
                filtros.doctores,
                filtros.tipoDoctor,
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `agendas.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error descargando el archivo', error);
        }
    }

    return (
        <div>
            <h2 className="text-3xl my-4 font-semibold">Ocupación de Agenda</h2>

            <AgendaFiltros 
                filtros={filtros}
                setFiltros={setFiltros}
                showFiltros={filtrosShowed}/>
            
            <DisplayCharts
                chartDisplay={chartDisplay}
                setChartDisplay={setChartDisplay}/>

            <button className={`w-fit h-[28px] bg-gray-900 text-white py-1 px-3 rounded-lg font-medium text-sm my-3`}
                onClick={onClickExportCsv}>
                Exportar
            </button>

            <div className={`${chartDisplay.layout === "Row" ? "grid grid-cols-2 justify-center" : "flex flex-col"} gap-2`}>

                {chartDisplay.showDaysChart && (
                    <VistaDias 
                        filtros={filtros}
                        layout={chartDisplay.layout} />
                )}

                {chartDisplay.showSucursalChart && (
                    <VistaSucursal 
                        filtros={filtros}
                        layout={chartDisplay.layout}/>
                )}

                {chartDisplay.showEspecialistaChart && (
                    <VistaEspecialidad 
                        filtros={filtros}
                        layout={chartDisplay.layout}/>
                )}

                {chartDisplay.showDoctoraChart && (
                    <VistaDoctor 
                        filtros={filtros}
                        layout={chartDisplay.layout}/>
                )}

            </div>
        </div>
    );
}