import { VistaGeneralData } from "../types/agenda";

export function calculateMaxRange(greatestGeneral: number, greatestEspecialista: number): number {
    const max = greatestEspecialista > greatestGeneral ? greatestEspecialista : greatestGeneral;

    let ejeY = max > 100 ? max + 10 : 100 ;

    while(ejeY % 10 !== 0) {

        ejeY += 1;
    }
    
    return ejeY;
}

/**
 * Calculate the next value divisible by ten of an axis y value.
 * 
 * @param { number } greatestValue - Axis Y value.
 * @returns { number } Axis Y value with padding.
 */
export function addPaddingToAxisYValue(greatestValue: number): number {
    let ejeY = greatestValue > 100 ? greatestValue + 10 : 100 ;

    while(ejeY % 10 !== 0) {

        ejeY += 1;
    }
    
    return ejeY;
}

/**
 * Get the greatest value from all datasets of an VistaGeneralData array.
 * 
 * @param { VistaGeneralData } datasets - Array of VistaGeneralData objects.
 * @returns { number } Greatest value.
 */
export function getGreatestValueFromDataset(datasets: VistaGeneralData[]): number {
    let greatestValue = 0;

    datasets.map((value) => {
        const greatest = value.projectionData.toSorted((a: number, b: number) => b - a)[0]

        if(greatest > greatestValue)
            greatestValue = greatest;
    });

    return greatestValue;
}