import { ChecklistModel, ChecklistRequest } from "../types";
import { ChecklistCreateModel, CorreosLista, HistorialRevision, TareaCreateModel } from "../types/checklist";
import { BaseService } from "./BaseService"

export class ChecklistServices extends BaseService {

    /**
     * @param { string } token - Auth token for getting access to API Backend.
     */
    constructor(token: string) {
        super(token, "/api/checklist")
    }

    /**
     * Get the daily checklist.
     * 
     * @returns { ChecklistModel } - Daily checklist data.
     */
    public async getDailyChecklist(id: string | undefined, sucursalId?: number): Promise<ChecklistModel> {
        const response = await this.axios.get<ChecklistModel>(`${id}`, {
            params: { sucursalId }
        });
        const { data } = response;
        return data;
    }

    /**
     * Save a new revision history record.
     * 
     * @param { ChecklistRequest } checklist - New checklist revision data.
     * @returns 
     */
    public async saveRevision(checklist: ChecklistRequest) {
        return this.axios.post("", checklist);
    }

    /**
     * Get a list of HistorialRevision.
     * 
     * @param { string } [fechaInicial] - Start date.
     * @param { string } [fechaFinal] - Finish date.
     * @returns { Array<HistorialRevision> } - List of Historial Revision.
     */
    public async getHistorialRevisiones(fechaInicial?: string, fechaFinal?: string): Promise<Array<HistorialRevision>> {
        const response = await this.axios.get<Array<HistorialRevision>>("/historial", { 
            params: { fechaInicial, fechaFinal }
        });
        const { data } = response;
        return data;
    }

    /**
     * Get the data of HistorialRevision.
     * 
     * @param { string | undefined } id - HistorialRevision id. 
     * @returns { HistorialRevision } - Return the data of that HistorialRevision id.
     */
    public async getRevisionHistoryById(id: string | undefined): Promise<HistorialRevision> {
        const response = await this.axios.get<HistorialRevision>(`/historial/${id}`);
        const { data } = response;
        return data;
    }

    /**
     * Get a list of checklist.
     * 
     * @returns { Array<ChecklistModel> } List of ChecklistModels.
     */
    public async getListChecklist(sucursalId?: number): Promise<Array<ChecklistModel>> {
        const response = await this.axios("lists", {
            params: { sucursalId }
        });
        const { data } = response;
        return data;
    }

    /**
     * Get a list of checklist.
     * 
     * @returns { Array<ChecklistModel> } List of ChecklistModels.
     */
    public async getRevisionsList(sucursalId?: number): Promise<Array<ChecklistModel>> {
        const response = await this.axios("revisiones", {
            params: { sucursalId }
        });
        const { data } = response;
        return data;
    }

    /**
     * Get a list by its id.
     * 
     * @param { string | undefined } id - List id.
     * @returns 
     */
    public async getListById(id: string | undefined): Promise<ChecklistModel> {
        const response = await this.axios.get(`lists/${id}`);
        const { data } = response;
        return data;
    }

    /**
     * Save a new Checklist in the data base.
     * 
     * @param { ChecklistCreateModel } list - New checklist data.
     * @returns 
     */
    public async saveList(list: ChecklistCreateModel) {
        return this.axios.post("new-list", list);
    }

    /**
     * Update data of a tarea by its id.
     * 
     * @param { number } id - Tarea id.
     * @param { TareaCreateModel } tarea - Updated tarea data.
     * @returns
     */
    public async updateTareaById(id: number, tarea: TareaCreateModel) {
        return this.axios.put(`lists/tareas/${id}`, tarea);
    }

    /**
     * Update data of a list by its id.
     * 
     * @param { number } id - List id.
     * @param { ChecklistCreateModel } lista - Updated list data.
     * @returns
     */
    public async updateListaById(id: number, lista: ChecklistCreateModel) {
        return this.axios.put(`lists/${id}`, lista);
    }

    public async deleteChecklist(id: number) {
        return this.axios.delete(`${id}`)
    }

    public async deleteTarea(id: number) {
        return this.axios.delete(`lists/tareas/${id}`)
    }

    /**
     * Get the attached emails of a list.
     * 
     * @param { number } listId - List id.
     * @returns { Promise<CorreosLista> } - A list of attached emails.
     */
    public async getCorreosAdjuntos(listId: number): Promise<CorreosLista> {
        const response = await this.axios.get<CorreosLista>(`lists/correos/${listId}`);
        const { data } = response;
        return data
    }

    /**
     * Delete an attached email from a list using the correo-list relation id.
     * 
     * @param { number } correoId - Correo-list id.
     * @returns 
     */
    public async deleteCorreoAdjunto(correoId: number) {
        return await this.axios.delete(`lists/correos/adjuntos/${correoId}`)
    }
}