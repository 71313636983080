import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { VistaGeneral } from '../../../types/agenda';
import { calculateMaxRange } from '../../../utilities/calculateMaxRange';

ChartJs.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    annotationPlugin
);

interface Props {
    chartConfig: VistaGeneral;
}

export function VistaGeneralChart({ chartConfig }: Props) {

    const greatestGeneral = chartConfig.proyeccionGeneral.toSorted((a: number, b: number) => b - a)[0];
    const greatestEspecialista = chartConfig.proyeccionEspecialista.toSorted((a: number, b: number) => b - a)[0];

    const max = calculateMaxRange(greatestGeneral, greatestEspecialista);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: max,
                stacked: chartConfig.proyeccionGeneral.length > 0 || chartConfig.proyeccionEspecialista.length > 0 ? true : false
            },
            x: {
                stacked: chartConfig.proyeccionGeneral.length > 0 || chartConfig.proyeccionEspecialista.length > 0 ? true : false
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: false,
                text: '',
            },
            datalabels: {
                display: true,
                color: "rgba(17, 24, 39, 1)",
                formatter: function(value: any, _context: any) {

                    if(value <= 0)
                        return "";

                    if(_context.dataset.label == "Proyeccion Generales")
                        return `${chartConfig.proyeccionGeneral[_context.dataIndex]}%`

                    if(_context.dataset.label == "Proyeccion Especialistas")
                        return `${chartConfig.proyeccionEspecialista[_context.dataIndex]}%`

                    return `${value}%`
                },
            },
            annotation: {
                annotations: {
                    line1: {
                    type: 'line',
                    yMin: 100,
                    yMax: 100,
                    borderColor: 'red',
                    borderWidth: 1,
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        let label = context.dataset.label;
                        if(label == "Proyeccion Generales")
                            return `${label}: ${chartConfig.proyeccionGeneral[context.dataIndex]}%`;

                        if(label == "Proyeccion Especialistas") 
                            return `${label}: ${chartConfig.proyeccionEspecialista[context.dataIndex]}%`;

                        if(label == "Generales" || label == "Especialidades" || label == "Doctores") 
                            return `${label}: ${chartConfig.general[context.dataIndex]}%`;

                        if(label == "Especialistas") 
                            return `${label}: ${chartConfig.especialista[context.dataIndex]}%`;

                        return label;
                    }

                }
            }
        },
    };

    const dataset = []

    if(chartConfig.general.length > 0) {
        dataset.push({
                label: chartConfig.generalDatasetName ?? "Generales",
                data: chartConfig.general,
                backgroundColor: chartConfig.generalColors.length > 0 ? chartConfig.generalColors : 'rgba(255, 99, 132, 0.5)',
                stack: 'Stack 0',
            });
        if(chartConfig.proyeccionGeneral.length > 0) {

            const dataProyectionGeneral: number[] = []

            for (let index = 0; index < chartConfig.proyeccionGeneral.length; index++) {
                let value = chartConfig.proyeccionGeneral[index] - chartConfig.general[index];
                dataProyectionGeneral.push(value);
            }

            dataset.push({
                    label: "Proyeccion Generales",
                    data: dataProyectionGeneral,
                    backgroundColor: "rgba(72, 72, 72, 0.5)",
                    stack: "Stack 0",
                });
        }
    }

    if(chartConfig.especialista.length > 0) {
        dataset.push({
                label: chartConfig.especialistaDatasetName ?? "Especialistas",
                data: chartConfig.especialista.length > 0 ? chartConfig.especialista : null,
                backgroundColor: chartConfig.especialistaColors.length > 0 ? chartConfig.especialistaColors : 'rgba(255, 99, 132, 0.5)',
                stack: 'Stack 1',
            });
        if(chartConfig.proyeccionEspecialista.length > 0) {

            const dataProyectionEspecialista: number[] = []

            for (let index = 0; index < chartConfig.proyeccionEspecialista.length; index++) {
                let value = chartConfig.proyeccionEspecialista[index] - chartConfig.especialista[index];
                dataProyectionEspecialista.push(value);
            }

            dataset.push({
                    label: "Proyeccion Especialistas",
                    data: dataProyectionEspecialista,
                    backgroundColor: "rgba(72, 72, 72, 0.5)",
                    stack: 'Stack 1',
                });
        }
    }

    if(chartConfig.datasets.length > 0) {
        chartConfig.datasets.map((data, index) => {
            dataset.push({
                label: data.datasetName,
                data: data.data,
                backgroundColor: data.colors,
                stack: "Stack 3",
            });

            dataset.push({
                label: `Proyeccion ${data.datasetName}`,
                data: data.projectionData,
                backgroundColor: "rgba(72, 72, 72, 0.5)",
                stack: "Stack 3",
            });
        });
    }

    const data = {
        labels: chartConfig.labels,
        datasets: dataset,
    };

    return (
        <Bar 
            redraw={true}
            options={options}
            data={data}
            plugins={[ChartDataLabels,annotationPlugin]}/>
    );
}