import { ChangeEvent, useEffect, useState } from 'react';
import { ComprasServices } from '../../services';
import { CorreoAdjuntoResponse } from '../../types';
import { useUser } from '../../hooks/useUser';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/joy';

interface Props {
    setValue: any;
}

export function EmailInput({ setValue }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";
    const comprasServices = new ComprasServices(token);

    const [inputValue, setInputValue] = useState("");
    const [strings, setStrings] = useState<Array<string>>([]);
    const [suggestions, setSuggestions] = useState<Array<CorreoAdjuntoResponse>>([]);
    const [suggestionsShowed, setSuggestionsShowed] = useState<Array<CorreoAdjuntoResponse>>([]);

    const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

    const validarCorreo = (correo: string) => {
        const expresionRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return expresionRegular.test(correo);
    }

    // Manejar el cambio en el input
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setSuggestionsShowed(suggestions.filter(sug => sug.correo.includes(value)));
    }

    // Manejar el envío del formulario
    const handleSubmit = () => {
        setInvalidEmail(false);

        if (inputValue.trim() !== '') {

            // validar que el input sea una direccion de correo valida.
            if(!validarCorreo(inputValue)) {
                setInvalidEmail(true);
                return;
            }

            setStrings([...strings, inputValue]);
            setInputValue('');
            setSuggestionsShowed([]);
        }
    }

    const handleDeleteEmail = (index: number) => {
        strings.splice(index, 1)
        setStrings([...strings]);
    }

    // Manejar la selección de sugerencias
    const handleSuggestionClick = (suggestion: string) => {

        if(strings.includes(suggestion)) {
            setInputValue('');
            setSuggestionsShowed([]);
            return;
        }

        setStrings([...strings, suggestion]);
        setInputValue('');
        setSuggestionsShowed([]);
    }

    useEffect(() => {
        setValue("correosAdjuntos", strings, { shouldDirty: true })
    }, [strings]);

    useEffect(() => {
        comprasServices.getCorreosAdjuntos()
            .then(data => setSuggestions(data));
    }, []);

    return (
        <div>

            <div className="flex gap-5">
                <input
                    className="inputform w-full"
                    type="email"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder="Ingresa un string"/>
                <button 
                    className="h-[26px] my-[8px] bg-blue-600 text-white px-3 py-[1px] rounded-md font-medium" 
                    type="button" 
                    onClick={handleSubmit}>
                        Agregar
                </button>
            </div>

            {invalidEmail && (
                <p className="valErrorText my-2">El correo ingresado es invalido</p>
            )}

            <div className='grid grid-cols-2'>

                <div>
                    <p className="font-semibold my-1 text-blue-900">Sugerencias</p>
                    <ul className="h-[96px] overflow-auto max-h-full">
                    {suggestionsShowed.length > 0 ?
                        <>
                            {suggestionsShowed.slice(0, 4).map((suggestion, index) => (
                                <li 
                                    className="my-1"
                                    key={index} 
                                    onClick={() => handleSuggestionClick(suggestion.correo)}>
                                {suggestion.correo}
                                </li>
                            ))}
                        </>
                        : <li className="font-normal italic">Sin sugerencias</li>
                    }
                    </ul>
                </div>

                <div>
                    <p className="font-semibold my-1 text-green-800 ">Correos seleccionados:</p>
                    <ul className="h-[96px] overflow-auto max-h-full">
                        {strings.map((string, index) => (
                        <li 
                            className="my-1"
                            key={index}>
                            <div className="flex justify-between">
                                {string}
                                <ClearIcon 
                                    className="hover:bg-gray-100 hover:rounded"
                                    onClick={() => handleDeleteEmail(index)}/>
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>

            </div>

        </div>
    );
}