import { useEffect, useState } from "react";
import { useUser } from "../../../hooks/useUser";
import { AgendaServices } from "../../../services";
import { FiltrosModel } from "../../../types";
import { ChartLayout, VistaGeneral } from "../../../types/agenda";
import { VistaGeneralChart } from "./VistaGeneralChart";
import { LoadingComponent } from "../../UtilsComponents";

interface Props {
    filtros: FiltrosModel;
    layout: ChartLayout;
}

export function VistaDoctor({ filtros, layout }: Props) {
    const { userContext } = useUser();
    const token = userContext.auth?.jwt ?? "";

    const agendaService = new AgendaServices(token);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dataVistaDoctor, setDataVistaDoctor] = useState<VistaGeneral>({
        labels: [],
        general: [],
        especialista: [],
        generalColors: [],
        especialistaColors: [],
        proyeccionGeneral: [],
        proyeccionEspecialista: [],
        datasets: [],
    });

    useEffect(() => {
        setIsLoading(true);

        agendaService.getVistaDoctorData(
            filtros.fechaInicial,
            filtros.fechaFinal,
            filtros.sucursales,
            filtros.especialidad,
            filtros.doctores,
            filtros.tipoDoctor,
        ).then(data => {
            setDataVistaDoctor(data);
            setIsLoading(false);
        });

    }, [filtros]);

    return (
        <div className={`${layout == "Row" ? "w-full col-span-2 flex justify-center" : "flex justify-center" } rounded-lg shadow-sm p-4 bg-slate-50 border-2 border-slate-200`}>
            <div className="w-10/12 p-4">
            {isLoading
            ? <LoadingComponent />
            : <VistaGeneralChart
                chartConfig={dataVistaDoctor} />
            }
            </div>
        </div>
    );
}